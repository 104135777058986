import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer/Footer";
import { logo as Logo, logoBlack } from "./Home/ImageGallary";
import HttpsRedirector from "./HttpsRedirector";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

import CookieModal from "./CookieModal";
import { useSmoothScrollToTop } from "../useSmoothScrollToTop";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const [showCookie, setShowCookie] = useState(false);
 

  useEffect(() => {
    const consentCookie = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("consent="));
    if (!consentCookie) {
      setTimeout(() => {
        setShowCookie(true);
      }, 5000);
    }
  }, []);

  const handleAccept = () => {
    setShowCookie(false);
  };
  function handleClose() {
    setShowCookie(false);
  }

  useEffect(() => {
    const consentCookie = document.cookie
      .split(";")
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith("consent=false"));
    if (consentCookie) {
      setShowCookie(false);
    }
  }, []);

  // scrolling to top
  useSmoothScrollToTop()

  return (
    <HttpsRedirector>
      <Navbar />
      {children}
      {showCookie && (
        <CookieModal close={handleClose} acceptHandle={handleAccept} />
      )}
      <Footer />
    </HttpsRedirector>
  );
};

export default Layout;
