import React from "react";
import { Link } from "gatsby";
import {
  PageNotFound,
  logoBlack,
} from "../components/Home/ImageGallary";
import Layout from "../components/Layout";
import { NavbarBackgroundProvider } from "../context/NavContext";
import ServicesFlex from "../components/About Us/ServicesFlex";

const NotFoundPage = () => {
  return (
    <>
      <NavbarBackgroundProvider
        backgroundColor={"#ffffff"}
        Logo={logoBlack}
        navbar={"x-navbar2"}
        navbarBlack={"navbar_black"}
      >
        <Layout>
          <div className="mt-5">
            <main className="d-flex justify-content-center flex-column text-center page-not-found">
              <div className="container py-5">
                <div className="row">
                  <div className="col-lg-12">
                    <img src={PageNotFound} alt="page not found" className="img-fluid" />
                  </div>
                </div>
                <Link to="/" className="">
                  <button className="secondary-btn-lg">Go Home</button>
                </Link>

                {/* <div className="row ">
                  <div className="col-lg-6 offset-lg-3">
                    <div
                      className="card p-3"
                      style={{ transform: "translateY(250px)" }}
                    >
                      <h1
                        style={{
                          fontSize: "60px",
                          fontWeight: "bold",
                          color: colors.primary,
                        }}
                      >
                        404
                      </h1>
                      <span>Page not found</span>
                      
                      
                      <p>
                        Sorry 😔, we couldn’t find what you were looking for.
                        <br />
                        <br />
                        <Link to="/" className="">
                          <button className="secondary-btn-lg">Go Home</button>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </main>
            <ServicesFlex />
          </div>
        </Layout>
      </NavbarBackgroundProvider>
    </>
  );
};

export default NotFoundPage;

// export const Head = () => <title>Not found</title>;
