import React, { useEffect } from "react";

const HttpsRedirector = ({ children }) => {
  useEffect(() => {
    function redirectToHttps() {
      if (
        typeof window !== "undefined" &&
        window.location.protocol === "http:"
      ) {
        const httpsUrl = window.location.href.replace("http:", "https:");
        // Redirect to the HTTPS URL
        window.location.href = httpsUrl;
      }
    }

    redirectToHttps();
  }, []); // Run the effect only once after the initial render

  return <>{children}</>;
};

export default HttpsRedirector;
