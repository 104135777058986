import React, { createContext, useContext, useState } from "react";

const NavbarBackgroundContext = createContext();

export function useNavbarBackground() {
  return useContext(NavbarBackgroundContext);
}

export function NavbarBackgroundProvider({
  children,
  backgroundColor = "#00000066",
  navbar,
  navbarBlack,
  Logo,
}) {
  const [background, setBackground] = useState("transparent");

  return (
    <NavbarBackgroundContext.Provider
      value={{
        backgroundColor,
        background,
        setBackground,
        navbarBlack,
        navbar,
        Logo,
      }}
    >
      {children}
    </NavbarBackgroundContext.Provider>
  );
}
