import React, { useState } from "react";
import { Cookie } from "./Home/ImageGallary";

const CookieModal = ({ close, acceptHandle, onGet, onSet, onRemove }) => {
  const [accepted, setAccepted] = useState(false);

  const handleAccept = () => {
    document.cookie =
      "consent=true; expires=" +
      new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000).toUTCString();
    acceptHandle();
  };

  const handleDecline = () => {
    document.cookie = "consent=false; expires=";
    close();
  };

  return (
    <div className="cookies-main">
      <div className="c_title">
        <img src={Cookie} alt="Cookie" className="img-fluid" />
        <h4>This site uses cookies.</h4>
      </div>
      <p>
        Cookies allow the website publisher to do useful things like find out
        whether the computer (and Probably its user) has visited the site
        before.
      </p>

      <div className="d-flex justify-content-start">
        <button className="secondary-btn mx-2 " onClick={handleDecline}>
          Decline
        </button>
        <button className="secondary-btn" onClick={handleAccept}>
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieModal;
