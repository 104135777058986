import img1 from "../../images/image/image-gallary/diwali.jpeg";
import img2 from "../../images/image/image-gallary/ofc-staff.jpg";
import img4 from "../../images/image/image-gallary/ofc-img (3).jpg";
import img3 from "../../images/image/image-gallary/event-img (2).jpeg";
import img5 from "../../images/image/image-gallary/ofc-img (9).jpg";
import img6 from "../../images/image/image-gallary/yoga.jpg";

import Logo from "../../images/image/Logo.png";
import FooterLogo from "../../images/image/logo2.png";
import logoBlack from "../../images/image/Logo3.png";
import reactjs from "../../images/image/technology/react.png";
import angularjs from "../../images/image/technology/angular.png";
import nodejs from "../../images/image/technology/nodejs.png";
import webSquarImg from "../../images/image/Web-Development.jpg";
import MobileSquarImg from "../../images/image/Mobile-App-Development-2.jpg";
import ErpSquarImg from "../../images/image/Customized-ERP-Development.jpg";
import StartupSquarImg from "../../images/image/Startup-Consultancy.jpg";
import Quality from "../../images/image/shield.png";
import scalability from "../../images/image/scalability.png";
import maintenance from "../../images/image/maintenance.png";
import xImg from "../../images/image/x-imag-banner.png";
import mission from "../../images/image/mission-target.png";
import coding from "../../images/image/technology/coding.png";
import server from "../../images/image/technology/server.png";
import cloud from "../../images/image/technology/cloud.png";
import onlineStore from "../../images/image/technology/online-store.png";
import mobileApp from "../../images/image/technology/mobile-app.png";
import energySystem from "../../images/image/technology/energy-system.png";
import process from "../../images/image/technology/process.png";
import appDevelopment from "../../images/image/technology/app-development.png";
import cloudApplication from "../../images/image/technology/cloud-application.png";
import pcImg from "../../images/image/pc.png";
import ourStrategy from "../../images/image/SDE.png";
import apiImg from "../../images/image/technology/api.png";
import seo from "../../images/image/technology/seo.png";
import support from "../../images/image/technology/customer-support.png";
import clarification from "../../images/image/technology/clarification.png";
import our_process from "../../images/image/Ourprocess.png";

import dedicatedTeam from "../../images/image/Online world-amico.svg";
import maintain from "../../images/image/Software code testing-rafiki.png";
import ecommerceShop from "../../images/image/In no time-bro.png";
import sdeDevelopment from "../../images/image/Software integration-amico.png";
import Ai from "../../images/image/OIG.UUTsOp0AHSPp7lTDKLLA.jpg";

import threeD from "../../images/image/consultoria-informatica-lr-03.5d43144.jpg";
import portability from "../../images/image/power-bank.png";
import feedback from "../../images/image/feedback.png";
import configuration from "../../images/image/configuration.png";
import content from "../../images/image/content-management.png";
import responsive from "../../images/image/responsive.png";
import book from "../../images/image/book.png";
import friendly from "../../images/image/user-friendly.png";
import efficiency from "../../images/image/efficiency.png";
import team from "../../images/image/team.jpg";
import methodology from "../../images/image/methodology.jpg";
import cover from "../../images/image/Custome-Software-Development.jpg";
import PageNotFound from "../../images/image/404.gif";
import favicon from "../../images/favicon.ico";
import favicon32 from "../../images/favicon-32x32.png";
import favicon16 from "../../images/favicon-16x16.png";
import thumbnail from "../../images/image/thumbnail.jpg";
import dashboard from "../../images/image/dashboard.png";
import epinaka from "../../images/image/epinaka.png";
import epinakalogo from "../../images/image/epinaka-logo.jpg";
import classadia from "../../images/image/classadia-app.png";
import classadialogo from "../../images/image/classadia-logo.png";
import likex from "../../images/image/likex-app.png";
import talentBattle from "../../images/image/tb-app.png";
import farmacia from "../../images/image/farmacia.png";
import farmacialogo from "../../images/image/farmacia-logo.png";
import likexlogo from "../../images/image/likex-logo.png";
import tblogo from "../../images/image/tb-logo.png";
import TbHero from "../../images/image/tb-hero.png";
import Cookie from "../../images/image/cookie.png";
import floatImage from "../../images/image/float-likex.png";
import floatImage2 from "../../images/image/float-likex2.png";
export const colors = {
  primary: " #1a9fda",
};

export const gallary = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },

  {
    img: img5,
  },

  {
    img: img6,
  },
];

export {
  Logo,
  FooterLogo,
  xImg,
  reactjs,
  mission,
  nodejs,
  angularjs,
  webSquarImg,
  MobileSquarImg,
  ErpSquarImg,
  StartupSquarImg,
  Quality,
  scalability,
  maintenance,
  logoBlack,
  coding,
  server,
  cloud,
  onlineStore,
  mobileApp,
  energySystem,
  process,
  appDevelopment,
  cloudApplication,
  pcImg,
  ourStrategy,
  apiImg,
  dashboard,
  seo,
  support,
  clarification,
  our_process,
  dedicatedTeam,
  maintain,
  ecommerceShop,
  sdeDevelopment,
  Ai,
  threeD,
  team,
  book,
  efficiency,
  portability,
  feedback,
  content,
  responsive,
  configuration,
  friendly,
  methodology,
  cover,
  PageNotFound,
  favicon,
  favicon16,
  favicon32,
  thumbnail,
  epinaka,
  epinakalogo,
  classadia,
  classadialogo,
  talentBattle,
  likex,
  farmacia,
  farmacialogo,
  tblogo,
  likexlogo,
  Cookie,
  TbHero,
  floatImage,
  floatImage2,
};
