import React from "react";

const Dmca = () => {
  return (
    <div>
      <a
        href="//www.dmca.com/Protection/Status.aspx?ID=a0eb60f5-b7d6-4513-a55c-57b70919e8ac"
        title="DMCA.com Protection Status"
        className="dmca-badge"
      >
        {" "}
        <img
          src="https://images.dmca.com/Badges/dmca-badge-w150-5x1-07.png?ID=a0eb60f5-b7d6-4513-a55c-57b70919e8ac"
          alt="DMCA.com Protection Status"
        />
      </a>{" "}
      <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
        {" "}
      </script>
    </div>
  );
};

export default Dmca;
