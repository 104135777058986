exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-505-js": () => import("./../../../src/pages/505.js" /* webpackChunkName: "component---src-pages-505-js" */),
  "component---src-pages-about-delogico-js": () => import("./../../../src/pages/about-delogico.js" /* webpackChunkName: "component---src-pages-about-delogico-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookiespolicy-js": () => import("./../../../src/pages/cookiespolicy.js" /* webpackChunkName: "component---src-pages-cookiespolicy-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-customized-erp-development-js": () => import("./../../../src/pages/customized-erp-development.js" /* webpackChunkName: "component---src-pages-customized-erp-development-js" */),
  "component---src-pages-datapolicy-js": () => import("./../../../src/pages/datapolicy.js" /* webpackChunkName: "component---src-pages-datapolicy-js" */),
  "component---src-pages-dedicated-development-js": () => import("./../../../src/pages/dedicated-development.js" /* webpackChunkName: "component---src-pages-dedicated-development-js" */),
  "component---src-pages-e-commerce-development-js": () => import("./../../../src/pages/eCommerce-development.js" /* webpackChunkName: "component---src-pages-e-commerce-development-js" */),
  "component---src-pages-hire-resources-js": () => import("./../../../src/pages/hire-resources.js" /* webpackChunkName: "component---src-pages-hire-resources-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-application-development-js": () => import("./../../../src/pages/mobile-application-development.js" /* webpackChunkName: "component---src-pages-mobile-application-development-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-software-maintanance-services-js": () => import("./../../../src/pages/software-maintanance-services.js" /* webpackChunkName: "component---src-pages-software-maintanance-services-js" */),
  "component---src-pages-startup-consultancy-js": () => import("./../../../src/pages/startup-consultancy.js" /* webpackChunkName: "component---src-pages-startup-consultancy-js" */),
  "component---src-pages-technology-details-js": () => import("./../../../src/pages/TechnologyDetails.js" /* webpackChunkName: "component---src-pages-technology-details-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

