import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import technologyList from "../data/data.json";
import { useNavbarBackground } from "../context/NavContext";
import { StaticImage } from "gatsby-plugin-image";

const Navbar = () => {
  const [isToggled, setToggled] = useState(false);
  const {
    background,
    setBackground,
    backgroundColor,
    navbarBlack,
    Logo,
    navbar,
  } = useNavbarBackground();

  // const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll > 1 && background !== backgroundColor) {
        setBackground(backgroundColor);
      } else if (scroll <= 1 && backgroundColor !== "transparent") {
        setBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [background, setBackground]);

  const handleToggle = () => {
    setToggled(!isToggled);
  };
  // const changeLanguage = (lng) => {
  //   i18next.changeLanguage(lng);
  //   console.log(lng);
  // };

  return (
    <div className="web-container">
      <header className={`${navbar} fixed-top `} style={{ background }}>
        <div className="container">
          <nav className="navbar  navbar-light navbar-expand-md">
            <Link to="/" target="_self" className="navbar-brand">
              <img src={Logo} height="40px" alt="Delogico" loading="lazy" />
            </Link>

            <button
              className={`hamburger__toggle navbar-toggler ${
                isToggled ? "toggled" : ""
              }`}
              style={{ cursor: "pointer" }}
              onClick={handleToggle}
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="toggle__button"
            >
              <span className="hamburger__icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className={`navbar-nav ${navbarBlack}`}>
                <li className="nav-item">
                  <Link className="nav-link" to="/hire-resources">
                    Hire Resources
                  </Link>
                </li>

                <li className="nav-item sm-menu">
                  <div className="nav-link dropdown-toggle">Services</div>

                  <div className="dropdown-menu animation slideDownIn">
                    <ul className="list-unstyled">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/customized-erp-development"
                        >
                          {"Customized Erp Development"}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/mobile-application-development"
                        >
                          {"Mobile Application Development"}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/web-development">
                          {"Web Development"}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to="/startup-consultancy"
                        >
                          {"Startup Consultancy"}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="nav-item">
                  <div className="nav-link large-dropdown dropdown-toggle">
                    Technology
                  </div>

                  <div className="large_dropdown_wraper animation slideDownIn">
                    <div className="row ">
                      <div className="col right-br">
                        <div className="head-section">
                          <p>Frontend Development</p>
                        </div>

                        <ul className="list-unstyled">
                          {technologyList.slug
                            .filter((item) => item.type === "frontend")
                            .map((slug, i) => (
                              <li key={i}>
                                <Link
                                  className="dropdown-item"
                                  to={`/technology/${slug.slug}`}
                                >
                                  {`${slug.title}`}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="col right-br">
                        <div className="head-section">
                          <p>Backend Development</p>
                        </div>
                        <ul className="list-unstyled">
                          {technologyList.slug
                            .filter((item) => item.type === "backend")
                            .map((slug, i) => (
                              <li key={i}>
                                <Link
                                  className="dropdown-item"
                                  to={`/technology/${slug.slug}`}
                                >
                                  {`${slug.title}`}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="col">
                        <div className="head-section">
                          <p>Mobile App Development</p>
                        </div>
                        <ul className="list-unstyled">
                          {technologyList.slug
                            .filter(
                              (item) => item.type === "mobile development"
                            )
                            .map((slug, i) => (
                              <li key={i}>
                                <Link
                                  className="dropdown-item"
                                  to={`/technology/${slug.slug}`}
                                >
                                  {`${slug.title}`}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="col">
                        <div className="head-section">
                          <p>Open-Source Development</p>
                        </div>
                        <ul className="list-unstyled">
                          {technologyList.slug
                            .filter((item) => item.type === "open-source")
                            .map((slug, i) => (
                              <li key={i}>
                                <Link
                                  className="dropdown-item"
                                  to={`/technology/${slug.slug}`}
                                >
                                  {`${slug.title}`}
                                </Link>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/work">
                    {"Work"}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    {"Contact"}
                  </Link>
                </li>
                {/* <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Language")}
                  </Link>
                  <ul
                    className="dropdown-menu animation slideDownIn"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li
                      onClick={() => {
                        // changeLanguage("es");
                      }}
                    >
                      <Link className="dropdown-item">es</Link>
                    </li>
                    <li
                      onClick={() => {
                        // changeLanguage("en")
                      }}
                    >
                      <Link className="dropdown-item">en</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
