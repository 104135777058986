import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import { useEffect } from "react";

export const useSmoothScrollToTop = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [pathname]);

  return null;
};
