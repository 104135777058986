import React from "react";
import { Link } from "gatsby";
import data from "../../data/data.json";
import { FooterLogo } from "../Home/ImageGallary";
import Dmca from "./Dmca";
import { navigate } from "gatsby";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className=" pt-5 pb-5  reveal footer-section">
        <div className="container">
          <div className="row mb-4">
            <div className=" col-lg-10  col-md-10">
              <Link to="/">
                <p>
                  <img
                    src={FooterLogo}
                    width="170px"
                    height="auto"
                    alt="delogico logo"
                  />
                </p>
              </Link>
            </div>
          </div>

          <div className="row item">
            <div className="  col-lg-2 col-md-6 col-sm-6 ">
              {data.footerLink.links.map((link, i) => (
                <p key={i}>
                  <Link to={link.path} className="footer-link ">
                    {link.name}
                  </Link>
                </p>
              ))}
            </div>
            <div className=" col-lg-3  col-md-6 col-sm-6 ">
              {data.footerLink.servicesLink.map((link, i) => (
                <p key={i}>
                  <Link to={link.path} className="footer-link ">
                    {link.name}
                  </Link>
                </p>
              ))}
            </div>
            <div className="offset-lg-1 col-lg-3 col-md-6   col-sm-6 d-flex flex-wrap flex-column">
              {data.slug.slice(0, 8).map((link, i) => (
                <p key={i}>
                  <Link
                    to={`/technology/${link.slug}`}
                    className="footer-link "
                  >
                    {link.title}
                  </Link>
                </p>
              ))}
            </div>
            <div className="offset-lg-0 col-lg-3 col-md-6 col-sm-6  d-flex flex-wrap flex-column">
              {data.slug.slice(8, 17).map((link, i) => (
                <p key={i}>
                  <Link
                    to={`/technology/${link.slug}`}
                    className="footer-link "
                  >
                    {link.title}
                  </Link>
                </p>
              ))}
            </div>

            {/* <div className="offset-lg-1 col-lg-3 col-md-4 offset-md-0">
            <p className="footer-title">Address</p>
            <div className="d-flex">
              <div className="s-icon">
                <i className="ri-map-pin-fill"></i>
              </div>
              <div className="address">
                701, Brooklyn Tower, <br />
                Near YMCA Club, <br />
                S.G. Highway,
                <br />
                Ahmedabad - 380051,
                <br />
                Gujarat - IN.
              </div>
            </div>
            <div className="d-flex">
              <div className="s-icon">
                <i className="ri-mail-fill"></i>
              </div>
              <Link to="mailto:info@delogico.com" className="Link-link">
                <p className="footer-text ">{"info@delogico.com"}</p>
              </Link>
            </div>
            <div className="d-flex">
              <div className="s-icon">
                <i className="ri-phone-fill"></i>
              </div>
              <div className="phone">
                <Link to="tel:990912992" className="Link-link">
                  <p className="footer-text">+91 9909 12992</p>
                </Link>
              </div>
            </div>
          </div> */}
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 ">
              <p className="footer-title">
                {"@ DELOGICO"} <span id="currentYear">{currentYear}</span>
              </p>

              <div className=" social-media p-0">
                <Link
                  to="https://www.facebook.com/profile.php?id=100074064287118"
                  target="_blank"
                  rel="nofollow"
                  className="social-btn fb"
                >
                  <i className="ri-facebook-box-fill footer-link d-inline"></i>
                </Link>
                <Link
                  to="https://twitter.com/DelogicoSystems"
                  target="_blank"
                  className="social-btn tw "
                  rel="nofollow"
                >
                  <i
                    className="ri-twitter-x-fill footer-link d-inline"
                    style={{ fontSize: "26px" }}
                  ></i>
                </Link>
                <Link
                  to="https://www.linkedin.com/in/delogico"
                  target="_blank"
                  className="social-btn in"
                  rel="nofollow"
                >
                  <i className="ri-linkedin-box-fill footer-link d-inline"></i>
                </Link>
                <Link
                  to="https://www.instagram.com/delogicosystems/"
                  target="_blank"
                  className="social-btn in"
                  rel="nofollow"
                >
                  <i className="ri-instagram-fill footer-link d-inline "></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 ">
              <div className="f-link-bx">
                <div className="d-flex  ">
                  <div className="s-icon">
                    <i className="ri-mail-fill"></i>
                  </div>
                  <Link to="mailto:info@delogico.com" className="Link-link">
                    <p className="footer-text ">{"info@delogico.com"}</p>
                  </Link>
                </div>
                <div className="d-flex ">
                  <div className="s-icon">
                    <i className="ri-phone-fill"></i>
                  </div>
                  <div className="phone">
                    <Link to="tel: 9909 912992" className="Link-link ">
                      <p className="footer-text">+91 9909 912992</p>
                    </Link>
                  </div>
                </div>
                <div className="dmc_badge mb-3">
                  <Dmca />
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 ">
              <div className="f-link-bx">
                <Link to="/privacypolicy" className="footer-link">
                  Privacy Policy
                </Link>

                <Link to="/datapolicy" className="footer-link">
                  Data Protection Policy
                </Link>
                <Link to="/cookiespolicy" className="footer-link">
                  Cookies Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
