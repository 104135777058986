import React from "react";
import { Link } from "gatsby";
import {
  ErpSquarImg,
  MobileSquarImg,
  StartupSquarImg,
  webSquarImg,
} from "../Home/ImageGallary";

const ServicesFlex = () => {
  return (
    <>
      <section className="sx">
        <div className="row my-lg-3">
          <div className="col-lg-3 col-md-6  mt-3">
            <Link to="/mobile-application-development">
              <img
                src={MobileSquarImg}
                alt="en-cta-desarrollo-de-aplicaciones-moviles.jpg"
                className="img-fluid cta"
              />
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 mt-3">
            <Link to="/web-development">
              <img
                src={webSquarImg}
                alt="en-cta-desarrollo-web.jpg"
                className="img-fluid cta"
              />
            </Link>
          </div>
          <div className="col-lg-3 col-md-6  mt-3">
            <Link to="/customized-erp-development">
              <img
                src={ErpSquarImg}
                alt="en-cta-consultoria-informatica.jpg"
                className="img-fluid cta"
              />
            </Link>
          </div>
          <div className="col-lg-3 col-md-6 mt-3">
            <Link to="/startup-consultancy">
              <img
                src={StartupSquarImg}
                alt="en-cta-diseno-grafico.jpg"
                className="img-fluid cta"
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesFlex;
